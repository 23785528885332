<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Toast />
        <h2>Perfil</h2>
        <BasicFormToolbar @new="openNew" @refresh="refresh" :actions="['new', 'refresh']" />
        <BasicDatatable :rowedit="true" :rowaction="true" :rowdelete="true" :headers="headers" :rows="entities" @edited="edit" @deleted="confirmDelete" />
        <Dialog
          v-model:visible="newDialog"
          :style="{ width: '85%' }"
          :header="!editMode ? 'Nuevo Perfil' : 'Editar Perfil'"
          :modal="true"
          class="p-fluid"
        >
          <div class="formgrid grid">
            <FormInputText wrapperClass="col-4 field" label="Clave" v-model="entity.key_name" :valid="validate.validations.key_name" />
            <FormInputText wrapperClass="col-8 field" label="Nombre" v-model="entity.name" :valid="validate.validations.name" />
            <div class="field col-8">
                <Tree @node-select="editAccion" :value="menus" selectionMode="checkbox" v-model:selectionKeys="selectedMenus"></Tree>
            </div>
            <div v-if="edited_menu" class="field col-4">
              <Panel :header="edited_menu.name">
                <div class="field-checkbox" v-for="action in edited_menu.actions" :key="action">
                    <Checkbox :id="action" :name="action" :value="action" v-model="cities" />
                    <label :for="action">{{actions.find(x => x.value == action).name}}</label>
                </div>
              </Panel>
            </div>
          </div>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              :loading="loading"
              @click="save"
            />
          </template>
        </Dialog>
        <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" /> 
      </div>
    </div>
  </div>
</template>

<script>
import { ERPProfile } from "../../models/seguridad/ERPProfile";
import { FilterMatchMode } from "primevue/api";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../utilities/General";
import Loader from "../../components/general/Loader.vue";
import Session from "../../mixins/sessionMixin";
import { Menu } from '../../models/seguridad/Menu';
import FormInputText from '../../components/general/FormInputText.vue';
import ConfirmDeleteDialog from '../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../components/general/BasicDatatable.vue';
import { actions } from "../../utilities/MENU_ACTIONS";
export default {
  mixins: [Session],
  data() {
    return {
      db_menus: [],
      entity: null,
      editMode: false,
      entities: [],
      menus: [],
      selectedMenus: null,
      edited_menu: null,
      //* URL para subir el importador
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      //* Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera
      //* 'required' de tipo 'text'
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })
      ],
      //* Aqui agregamos el estatus de la validacion, inicializar en las props 'null'
      //* y el valid general en false (por que no esta validado aun)
      validate: {
        valid: false,
        validations: {
          name: null,
          key_name: null
        },
      },
      //* Headers donde pondremos el nombre y el valor del datatable
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name")
      ],
      loading: false,
      actions: actions
    };
  },
  components: { Loader, FormInputText, BasicFormToolbar, BasicDatatable, ConfirmDeleteDialog },
  watch: {
    newDialog(newValue) {
      if (!newValue) {
        this.selectedMenus = null;
      }
    }
  },
  created() {
    this.entity = new ERPProfile(this.session);
    this.initFilters();
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    editAccion(payload) {
      let edited = this.db_menus.find(x => x.id == payload.key);
      this.edited_menu = edited.actions.length == 0 ? null : edited;

    },
    onRowContextMenu(event) {
        this.$refs.cm.show(event.originalEvent);
    },
    openNew() {
      this.entity = new ERPProfile(this.session);
      this.newDialog = true;
      this.editMode = false;
    },
    hideDialog() {
      this.newDialog = false;
      this.selectedMenus = null;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        if (this.entity.parent == "") {
          this.entity.parent = null;
        }
        if (this.entity.url == "") {
          this.entity.url = null;
        }
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        let profile_details = Object.keys(this.selectedMenus).map(menu => {
          return {
            id: menu
          }
        });

        let my_menu = [];
        profile_details.forEach(element => {
          my_menu.push(this.db_menus.find(x => x.id == element.id));
        });
        console.log(my_menu);
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.editMode) {
          let entity = await this.entity.update();
          this.entity.id = entity.id;
          await this.entity.saveDetail(profile_details);
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.entity.id = entity.id;
          await this.entity.saveDetail(profile_details);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new ERPProfile(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async edit(entity) {
      try {
        this.loading = true;
        this.entity = fillObject(this.entity, entity);
        let details = await this.entity.getDetail();
        let selectedMenus = {};
        details.forEach(detail => {
          Object.defineProperty(selectedMenus, detail.menu, {
            value: {
              checked: true,
              partialChecked: false
            },
            writable: true,
            enumerable: true,
            configurable: true
          });          
        });
        this.selectedMenus = selectedMenus;

        this.newDialog = true;
        this.editMode = true;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new ERPProfile(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    uploadError() {
      this.$toast.add(new ErrorToast("Error al intentar importar archivo"));
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
        let menus = await new Menu().all();
        this.db_menus = menus;
        menus.filter(x => x.parent == null).forEach(menu => {
          let children = menus.filter(y => y.parent == menu.id).map(p => {
              return {
                key: p.id,
                label: p.name
              }
            })

          children.forEach(child => {
            child.children = menus.filter(z => z.parent == child.key).map(p => {
              return {
                key: p.id,
                label: p.name
              }
            })
          });
          
          this.menus.push({
            key: menu.id,
            label: menu.name,
            children: children
          })
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
