import axios from 'axios';
import {
    Abstract
} from './Abstract';


export class ERPProfile extends Abstract {
    constructor(session) {
        super('security/ERPProfile', session);
        this.name = null;
        this.key_name = null;
        this.menus = [];
    }

    async saveDetail(details) {
        let response = await axios.post(this.controller + '/details/' + this.id, details, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async getDetail() {
        let response = await axios.get(this.controller + '/details/' + this.id, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }
}
